export function getRules(vueDom) {
  function validateConfirm(rule, value, callback) {
    console.log(vueDom.form.new_password)
    return value == vueDom.form.new_password ? callback() : callback(new Error('两次输入密码不一致'))
  }

  return {
    old_password: { required: true, message: '请输入旧密码', trigger: 'change' },
    new_password: { required: true, message: '请输入新密码', trigger: 'change' },
    confirm: { validator: validateConfirm, trigger: 'blur' },
  }
}