<template>
  <div class="container">
    <a-card class="content">
      <div class="title">修改密码</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          hideRequiredMark
        >
          <a-form-model-item label="旧密码" prop="old_password">
            <a-input v-model="form.old_password" type="password" size="large" />
          </a-form-model-item>
          <a-form-model-item label="新密码" prop="new_password">
            <a-input v-model="form.new_password" type="password" size="large" />
          </a-form-model-item>
          <a-form-model-item label="确认密码" prop="confirm">
            <a-input v-model="form.confirm" type="password" size="large" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-row class="button">
        <a-col :offset="4" :span="18">
          <a-button type="primary" size="large" block @click="setPassword"
            >确认修改</a-button
          >
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { getRules } from "./rules.js";

export default {
  name: "SetPassword",
  data() {
    return {
      rules: getRules(this),
      form: {
        old_password: "",
        new_password: "",
        confirm: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
  },
  methods: {
    setPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post("/users/reset_password/", this.form).then((res) => {
            this.$message.success("操作成功");
            this.$store.dispatch("logout").then((res) => {
              this.$router.push("/user/login");
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 480px;
  margin: auto;
  text-align: left;
}

.content {
  padding: 12px 0;
}

.title {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.button {
  padding-bottom: 24px;
}
</style>